/* Walldesign.css */

.wall-form-container {
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Changed to stack children vertically */
  align-items: stretch; /* Ensure children stretch to match the container's width */
}

/* Adjust the styling for the section containing the wall image and data entry form */
.wallImageAndDataEntry {
  display: flex;
  flex-direction: column; /* Stack image and form vertically */
  align-items: center; /* Center-align the items for a better appearance on wide screens */
  margin-bottom: 20px; /* Add some space between this section and the next */
}

.CalcCols1 {
  display: flex; 
  border : 2px solid red;
  margin: 5px;
  padding : 5px;
}

.DesignSheetsCantileverWall {
  margin: 5px;
  padding : 10px;
  background-color: white;
  border-radius: 1em;
}

.active-button {
  background-color: #007bff; /* Blue background for active tab */
  color: white; /* White text for active tab */
}




.form-group {
  display: flex; /* Use flexbox to align label and input */
  align-items: center; /* Align items vertically */
  margin-bottom: 2px;
}

.form-group label {
  flex: 0 0 300px; /* Give the label a fixed width */
  margin-right: 10px; /* Add some space between the label and the input */
  text-align: right; /* Right-align the text of the label */
}

.form-group input {
  flex: 0.1; /* Allow the input to take up the remaining space */
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.left-aligned-heading {
  text-align: left;
  width: 100%; /* Ensure the heading takes full width to align text properly */
  padding-left: 20px; /* Adjust as needed for your design */
}


/* Add some styles to your submit button for better appearance
button[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

button[type="submit"]:hover {
  background-color: #0056b3;
} */

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
    align-items: stretch;
  }

  .form-group label {
    flex: none; /* Do not allow the label to grow */
    margin-bottom: 1px; /* Add some space between the label and the input */
    text-align: left; /* Left-align the text of the label */
  }

  .form-group input {
    width: 100%; /* Make the input take the full width */
  }
}

/* General styles for tables within DesignSheetsCantileverWall */
.DesignSheetsCantileverWall table {
  width: auto; /* Ensures table takes full width of its container */
  margin: 0 auto; /* Center the table horizontally */
  border-collapse: separate; /* Necessary for border-spacing to work */
  border-spacing: 50px 0; /* Increase space between columns, no space added between rows */
}

/* Styles for table cells to center text and add padding */
.DesignSheetsCantileverWall th, .DesignSheetsCantileverWall td {
  text-align: center; /* Center-align text horizontally */
  vertical-align: middle; /* Center-align content vertically */
  padding: 5px; /* Add padding inside cells for spacing around content */
}

/* Left-align the first column */
.DesignSheetsCantileverWall th:first-child, .DesignSheetsCantileverWall td:first-child {
  text-align: left; /* Left-align text for the first column */
}

.bearingAndSlidingCheck {
  display : flex;
  flex-direction : row;
  border: 2px solid black ;
  padding: 0.5em;
  margin: auto;
}

/* #Toe_Load_Calculation_Table th:nth-of-type(1){width :25%} */

/* WallDesign.css */

.wall-visual {
    display: inline-block;
    margin-top: 20px;
    background-color: #656565; /* Wall color */
    color: white; /* Text color for any labels or dimensions */
    border: 2px solid #404040; /* Border to highlight the wall */
  }
  
  input {
    margin: 10px;
    padding: 5px;
  }
  
  .wall-visualizer-svg {
    background-color: white;
    border : 0.25px solid gray ;
    border-radius: 10px; 
  }
@keyframes slideshow {
    0%, 20% {
      background-image: url('/public/background 1.jpg'); /* Image 1 */
      opacity: 1;
    }
    
    20%, 40% {
      background-image: url('/public/background 2.jpg'); /* Image 2 */
      opacity: 1;
    }
    
    40%, 60% {
      background-image: url('/public/background 3.jpg'); /* Image 3 */
      opacity: 1;
    }
    
    60%, 80% {
      background-image: url('/public/background 4.jpg'); /* Image 4 */
      opacity: 1;
    }
    
    80%, 100% {
      background-image: url('/public/background 5.jpg'); /* Image 5 */
      opacity: 1;
    }
  }
  
  .main-content {
    text-align: center;
    padding: 20px;
    background-size: cover;
    background-position: center;
    animation: slideshow 10s infinite steps(1);
  }
  
  


/* Initial state with full opacity to ensure visibility */
.main-content h1, .main-content p {
    -webkit-mask-image: linear-gradient(to bottom, rgba(196, 34, 34, 0) 0%, rgba(10, 1, 1, 0.901) 100%);
    mask-image: linear-gradient(to bottom, rgba(212, 13, 6, 0) 0%, rgba(0,0,0,1) 100%);
}

.main-content h1 {
    font-size: 5em;
    color: #020114;
    transform: translateY(0); /* Ensure it starts in its final position */
}

/* Only apply transformations when .visible class is added */
.main-content h1.visible {
    opacity: 1;
    transform: translateY(0);
}

.main-content p {
    font-size: 2em;
    color: #090323; /* Set your desired text color here */
    transform: translateY(0); /* Ensure it starts in its final position */
    font-weight: bold; /* Make text bold */
}

.main-content p.visible {
    opacity: 1;
    transform: translateY(0);
}



/* News section styling for full left alignment */
.news-section {
    margin-left: 10; /* Aligns the section to the very left */
    padding-left: 50px; /* Adds some spacing from the edge of the browser window */
    text-align: left; /* Ensures content is aligned to the left */
}

.news-section h1 {
    color: #333; /* Dark grey color for the heading */
    font-size: 34px; /* Heading font size */
}

.news-section ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0;
    margin: 0; /* Removes default margin */
}

.news-section li a {
    display: block; /* Makes the links block elements to occupy full available width */
    color: #0066cc; /* Link color */
    font-size: 20px; /* Link font size */
    text-decoration: none; /* Removes underline from links */
    padding: 8px 0; /* Adds padding to each link for better readability */
}

.news-section li a:hover {
    text-decoration: underline; /* Adds underline on hover */
}

.news-section li a:visited {
  color: #663399; /* Changes the color of visited links */
}
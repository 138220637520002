/* FormStyles.css */

.form-container {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h2 {
    margin-top: 0;
    color: #333;
  }
  
  .form-container p {
    color: #666;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form button {
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form button:hover {
    background-color: #003d82;
  }
  
  .navbar {
    background-color: #f8f8f8;
    padding: 10px;
    text-align: center;
  }
  
  .navbar a {
    margin-right: 10px;
    color: #0056b3;
    text-decoration: none;
  }
  
  .navbar a:hover {
    text-decoration: underline;
  }
  
/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
}

.nav-links {
  display: flex;
}

.nav-links a,
.nav-user a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.nav-links a:hover,
.nav-user a:hover {
  background-color: #70717b58; 
  /* color of shading that comes when you move over the icons */
  border-radius: 5px;
}

.nav-user {
  display: flex;
  align-items: center;
}

.username {
  margin-right: 20px;
}

.logout-button {
  background-color: #f44336; /* Red color for logout to make it stand out */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f; /* Darken the button slightly on hover */
}
